/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://onycrvdr7zhdrgcxv32eww4joq.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qv4kcgsh2rfllmsgzjtbk2vbka",
    "aws_cognito_identity_pool_id": "eu-north-1:413891d2-9bf2-4d7a-bc28-0ada9e9ec875",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_teJiddkpn",
    "aws_user_pools_web_client_id": "16vgrkahaa40pl8n5is4hggtlh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplify-hospiyou-staging-82681-deployment",
    "aws_user_files_s3_bucket_region": "eu-north-1"
};


export default awsmobile;
